<template>
  <div>
    <a-card :bordered="false">
      <a-button
        type="primary"
        icon="plus"
        @click="handleAdd"
        v-if="hasAction('add-role')"
        >新建</a-button
      >
      <a-table
        ref="table"
        size="default"
        :row-key="(record) => record.roleId"
        :columns="columns"
        :data-source="roleList"
        :pagination="{ pageSize: 10 }"
      >
        <div slot="action" slot-scope="record">
          <template>
            <a @click="handle(record)" v-if="hasAction('edit-role')"
              >修改权限</a
            >
            <a-divider
              type="vertical"
              v-if="hasAction('edit-role') && hasAction('delete-role')"
            />
            <a-popconfirm
              title="确认移除吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="handleDelete(record)"
              v-if="hasAction('delete-role')"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </div>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getAllRoles, deleteRole } from "@/api/auth";
import RoleForm from "../components/RoleForm";
import RuleEdit from "../components/RuleEdit";

let columns = [
  {
    title: "#",
    dataIndex: "roleId",
  },
  {
    title: "角色名称",
    dataIndex: "roleName",
  },
];

export default {
  data() {
    return {
      columns: columns,
      roleList: [],
      selectedRole: {},
    };
  },
  created() {
    if (
      (this.hasAction("edit-role") || this.hasAction("delete-role")) &&
      this.columns.length < 3
    ) {
      this.columns.push({
        title: "操作",
        scopedSlots: {
          customRender: "action",
        },
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      getAllRoles().then((res) => {
        if (res.code === 0) {
          this.roleList = res.data;
        }
      });
    },
    handleAdd() {
      this.$dialog(
        RoleForm,
        {
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增",
          width: 700,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEdit(record) {
      this.selectedRole = record;
      this.showInfo = !this.showInfo;
    },
    handleDelete(record) {
      deleteRole(record).then(() => {
        this.loadData();
      });
    },
    handle(record) {
      this.$dialog(
        RuleEdit,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "权限管理",
          okText: "保存",
          width: 1000,
          centered: true,
          maskClosable: false,
        }
      );
    },
  },
};
</script>