<template>
  <a-form :form="form">
    <a-form-item label="角色名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入角色名称" v-decorator="['name']" />
    </a-form-item>
  </a-form>
</template>

<script>
import { addRole } from "@/api/auth";

export default {
  name: "TaskForm",
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              name: values.name,
            };
            addRole(formData).then(() => {
              this.$message.success("添加成功");
              resolve(true);
            });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
