<template>
  <a-tabs type="card" @change="callback">
    <a-tab-pane key="roleList" tab="角色列表"><role-list /></a-tab-pane>
  </a-tabs>
</template>

<script>
import RoleList from "./role-list";

export default {
  components: {
    RoleList,
  },
  methods: {
    callback() {},
  },
};
</script>